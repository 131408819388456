.textOrange {
  color: darkorange;
}

.titleIcon {
  margin: 0 0.5rem;
}

.listItem {
  padding: 1rem;
  cursor: pointer;
}

.listItem .notesTitle {
  display: flex;
  align-items: center;
}

.listItem .notesContent {
  margin-left: 3rem;
  text-align: justify;
}

.paginationBtnWrapper {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
}

.paginationBtnWrapper button:disabled,
.paginationBtnWrapper button[disabled] {
  border: 1px solid #dddddd;
  color: #aaaaaa;
  cursor: not-allowed;
  pointer-events: all !important;
}
