.pagination {
  padding: 2rem;
  text-align: center;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-around;
  color: #1976d2;
}

.allSet{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem;
}