.dynamicArea {
  margin-top: 4rem;
  position: relative;
  display: flex;
}

.dynamicArea .content {
  width: 55vw;
  margin-left: 350px;
  min-height: calc(100vh - 4rem);
}

.dynamicArea .content p{
  line-height: 2rem;
}

.zeal_adArea {
  width: 22vw;
}

.marginLeft300 {
  margin-left: 350px !important;
  transition: margin-left 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.marginLeft0 {
  margin-left: 0 !important;
  padding-left: 1rem;
  transition: margin-left 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  width: 70vw !important;
}

.displayBlock {
  width: 325px;
  transition: width 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.displayNone {
  width: 0;
  transition: width 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@media (max-width: 1300px) {
  .dynamicArea .content {
    width: 50vw;
  }

  .zeal_adArea {
    width: 20vw;
  }
}

@media (max-width: 1000px) {
  .dynamicArea .content {
    width: 40vw;
  }
  .zeal_adArea {
    width: 18vw;
  }
}

@media (max-width: 850px) {
  .dynamicArea {
    flex-direction: column;
  }
  .dynamicArea .content,
  .zeal_adArea {
    width: calc(100vw - 2rem) !important;
    margin-left: 0 !important;
  }
}
