.textRed {
  color: red;
}

.singleQuestion {
  padding: 1rem;
  margin-bottom: 1rem;
}

.light .singleQuestion {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.dark .singleQuestion {
  box-shadow: rgba(150, 150, 150, 0.24) 0px 3px 8px;
}

.singleQuestion > div {
  margin: 0.5rem auto;
}

.singleQuestion > h3{
display: flex;
justify-content: space-between;
}

.singleQuestion h3 {
  display: flex;
  align-items: center;
}

.checkIcon {
  margin: auto 0.5rem;
}
#standard-basic {
  color: orange;
}

.tagWrapper {
  display: flex;
  flex-wrap: wrap;
}

.tagWrapper .tagItem {
  margin: 0.5rem;
  padding: 0.5rem;
  color: darkorange;
  font-weight: 700;
}

.optionWrapper{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
 
.questionOption {
  display: flex;
  align-items: center;
}

.questionOption input {
  margin-right: 1rem;
}

.discussBtn{
  cursor: pointer;
}
