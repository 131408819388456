:root {
  --dark-bg: rgb(18, 18, 18);
  --dark-bg-sec: rgb(37, 37, 37);
  --light-bg: rgb(255, 255, 255);
  --ligh-bg-sec: rgb(0, 0, 0, 0.8);
  --dark-text: rgba(0, 0, 0, 0.8);
  --light-text: rgba(255, 255, 255, 1);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.dark-bg {
  background-color: var(--dark-bg);
}

.light-bg {
  background-color: var(--light-bg);
}

.dark-bg-sec {
  background-color: var(--dark-bg-sec);
}

.dark-text {
  color: var(--light-text);
}

.light-text {
  color: var(--dark-text);
}

.dark {
  background-color: var(--dark-bg-sec);
  color: var(--light-text);
}

.light {
  color: var(--dark-text);
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-track {
  background: var(--dark-bg-sec);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(150, 150, 150);
}

a {
  text-decoration: none;
}
img,
.image_resized {
  max-width: 100% !important;
  max-height: auto;
}
