.topnav {
  padding: 0.3rem;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 3.4rem;
  z-index: 9;
  -webkit-box-shadow: 0px 0px 30px -16px rgba(79, 76, 79, 1);
  -moz-box-shadow: 0px 0px 30px -16px rgba(79, 76, 79, 1);
  box-shadow: 0px 0px 30px -16px rgba(79, 76, 79, 1);
}

.topnav,
.topnav .leftMenu,
.topnav .rightMenu {
  display: flex;
  align-items: center;
}

.topnav .rightMenu {
  margin-left: auto;
}

.topnav .leftMenu .logoWrapper {
  max-width: 7.5rem;
  margin-left: 1rem;
}

.topnav .leftMenu .logoWrapper img {
  width: 100%;
  height: auto;
}

.topnav .leftMenu button,
.topnav .rightMenu button {
  padding: 0.8rem !important;
}

.topnav .rightMenu .homeButton {
  padding-bottom: 0.6rem !important;
}
