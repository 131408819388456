.sidenav {
  width: 325px;
  height: calc(100vh - 4rem);
  overflow-y: scroll;
  position: fixed;
  top: 50;
  left: 0;
  z-index: 9;
}

.pro-sidebar {
  width: 320px !important;
}

.pro-sidebar-inner {
  width: 320px;
}

@media (max-width: 850px) {
}

.active{
  color: red !important;
}