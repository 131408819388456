.tagWrapper {
  display: flex;
  align-items: center;
}

.tagWrapper .tag {
  padding: 0.5rem;
  border-radius: 0.3rem;
  background-color: #ddd;
  margin: auto 0.5rem;
  color: #000;
}

.specialTag {
  color: orangered;
  text-decoration: underline;
}
