.zeal_adArea {
  padding: 1rem;
  padding-bottom: 300px;
}

.zeal_adArea .fixedAd {
  position: fixed;
  z-index: 9;
  bottom: 0;
  right: 0;
  padding: 0.3rem;
  background-color: #fff;
  /* width: 325px; */
  width: 20vw;
}

.zeal_adArea .imgWrapper {
  padding-top: 1rem;
  width: calc(95%);
  margin: auto;
}

.zeal_adArea li {
  padding: 0.3rem;
}

.zeal_adArea a {
  color: inherit;
}

@media (max-width: 850px) {
  .fixedAd {
    display: none;
  }
}

@media (max-width: 1300px) {
  .zeal_adArea .fixedAd{
    width: 18vw;
  }
}

@media (max-width: 1000px) {
  .zeal_adArea .fixedAd{
    width: 16vw;
  }
}