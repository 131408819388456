.container {
  margin-left: 1rem;
}

.containerLast {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Notes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.mycard {
  -webkit-box-shadow: 0px 0px 30px -16px rgba(79, 76, 79, 1);
  -moz-box-shadow: 0px 0px 30px -16px rgba(79, 76, 79, 1);
  box-shadow: 0px 0px 30px -16px rgba(79, 76, 79, 1);
  margin: 1rem;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  width: 35%;
}

.container .heading {
  display: flex;
  align-items: center;
}

@media (max-width: 1400px) {
  .mycard {
    width: 35%;
  }
}

@media (max-width: 1100px) {
  .containerLast,
  .Notes {
    justify-content: center;
  }
  .mycard {
    width: 55%;
  }
}

@media (max-width: 850px) {
  .containerLast,
  .Notes {
    justify-content: space-between;
  }
  .mycard {
    width: 35%;
  }
}

@media (max-width: 600px) {
  .containerLast,
  .Notes {
    justify-content: center;
  }
  .mycard {
    width: 55%;
  }
}


.welcome{
  font-family: Arial, Helvetica, sans-serif;
}

.tagline{
  font-family:Verdana, Geneva, Tahoma, sans-serif
}

.aboutus{
  text-align: justify;
  line-height: 1.3rem;
}

.imgWrapper{
  width: 100%;
  margin-bottom: 2rem;
}

.imgWrapper img{
  max-width: 90%;
  display: block;
  margin: auto;
}